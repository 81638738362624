import React from 'react';


function Experience(props) {
    return (
        <div className="m:px-0 px-6 py-6 p-1 sm:mb-0 mb-6 rounded-lg bg-white shadow sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:grid-rows-1">
            <div className="col-span-1">
                <img src={props.imgLink} alt={props.imgAlt} className="object-scale-down h-52 w-68"/>
            </div>
            <div className="col-span-2">
                <h2 className="text-xl font-bold pt-4">{props.sectionTitle}</h2>
                <p className="text-md pb-4">{props.sectionTxt}</p>
            </div>
        </div>
      );
}

export default Experience;