import React from 'react';


function Service(props) {
    return (
        <div className="p-3 rounded-lg shadow-lg bg-cyan-500 md:grid justify-items-center mb-4 md:mb-0 flex flex-col md:mx-0 mx-6">
            <h3 className="text-lg font-bold text-center">{props.sectionTitle}</h3>
            <p className="mt-2">{props.sectionTxt}</p>
            <img src={props.sectionImg} alt={props.sectionImgAlt} className="mt-4 object-scale-down md:h-28 md:w-28 h-20 w-20 self-center"/>
        </div>
      );
}

export default Service;