import React from 'react';
import NavLink from '../Components/NavBar/NavLinks';
import NavLogo from '../Components/NavBar/NavLogo';
import LanguageSelector from '../Components/NavBar/LanguageSelector'
import { useTranslation } from 'react-i18next'

export default function NavBar() {

    const { t } = useTranslation()

  return (
    <div className="min-h-full">
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="flex items-center justify-between h-16">

            <div className="flex items-center">
              <div className="hidden sm:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <NavLink toLink="/" linkName={t('page.home.title')} />
                  <NavLink toLink="/projects" linkName={t('page.projects.title')} />
                  <NavLink toLink="/experiences" linkName={t('page.exp.title')} />
                  <NavLink toLink="/contact" linkName={t('page.contact.title')} />
                </div>
              </div>
              <div className="block sm:hidden">
                <div className="ml-10 flex items-baseline space-x-4">
                  <h1 className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md text-md font-medium">Nicolas Bazinet</h1>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="ml-4 flex items-center md:ml-6">

                <LanguageSelector />

                <div className="hidden sm:block ml-3 relative text-gray-300 rounded-md text-sm font-medium">
                  <p>Nicolas Bazinet</p>
                </div>

                <NavLogo logoName="GitLab Link" imgHref="https://gitlab.com/nbazinet" imgLink="images/gitlab-logo.png" imgAlt="GitLab"/>
                <NavLogo logoName="LinkedIn Link" imgHref="https://www.linkedin.com/in/nicolas-bazinet-196a8513a/" imgLink="images/linkedin.png" imgAlt="Linkedin"/>

              </div>
            </div>
          </div>

          <div className="block sm:hidden">
            <div className="flex items-baseline pb-4">
              <NavLink toLink="/" linkName={t('page.home.title')} />
              <NavLink toLink="/projects" linkName={t('page.projects.title')} />
              <NavLink toLink="/experiences" linkName={t('page.exp.title')} />
              <NavLink toLink="/contact" linkName={t('page.contact.title')} />
            </div>
          </div>

        </div>

      </nav>
    </div>
  );
}


/*
Mobile : Will do later 


              <button type="button" className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

    <div className="md:hidden" id="mobile-menu">
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Dashboard</a>

        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>

        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>

        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Reports</a>
      </div>
      <div className="pt-4 pb-3 border-t border-gray-700">
        <div className="flex items-center px-5">
          <div className="flex-shrink-0">
            <a href="https://www.linkedin.com/in/nathalie-gauthier-coach/" target="_blank" aria-label="LinkedIn" title="LinkedIn" rel="noopener noreferrer">
              <img src="linkedin.png" width="32" height="32" alt="Linkedin link" data-src="linkedin.png" className="lozad" typeof="foaf:Image" data-loaded="true" />
            </a>
          </div>
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
          </div>
          <div className="ml-3">
            <div className="text-base font-medium leading-none text-white">Tom Cook</div>
            <div className="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
          </div>
          <button type="button" className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button>
        </div>
      </div>
    </div>
*/