import React from 'react';
import Footer from '../Layouts/Footer.js';
import Project from '../Components/Projects/Project.js';
import { useTranslation } from 'react-i18next'

function Projects() {

    const { t } = useTranslation()

  return (
    <div className="Projects">
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">{t('page.projects.title')}</h1>
            </div>
        </header>
        <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="sm:px-0 px-6 py-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:grid-rows-2 sm:items-start sm:gap-16">

            <Project imgLink="images/merx.png" imgAlt="merx-logo" projectName={t('project.merx.cloud.title')}
                     description={t('project.merx.cloud.desc')} />


            <Project imgLink="images/asc.jpg" imgAlt="asc-logo" projectName={t('project.asc.cloud.title')}
                     description={t('project.asc.cloud.desc')} />

            <Project imgLink="images/BND-CMS-logo.jpg" imgAlt="bidnet-logo" projectName={t('project.bidnet.dev.title')}
                     description={t('project.bidnet.dev.desc')} />

            <Project imgLink="images/monitoring.jpg" imgAlt="monitoring-logo" projectName={t('project.monitoring.title')}
                     description={t('project.monitoring.desc')} />

            <Project imgLink="images/dependency.jpg" imgAlt="dependency-logo" projectName={t('project.eol.tracker.title')}
                     description={t('project.eol.tracker.desc')} />

            <Project imgLink="images/comingsoon.gif" imgAlt="comingsoon-logo" projectName={t('project.comingsoon.title')}
                     description={t('project.comingsoon.desc')} />

          </div>
        </div>
      </main>

        <Footer />

    </div>
  );
}

export default Projects;
