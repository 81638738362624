import React from 'react';
import Footer from '../Layouts/Footer.js';
import ContactLinkBar from '../Components/Contact/ContactLinkBar.js';
import emailkey from '../Components/Contact/emailkey.js';
import emailjs from 'emailjs-com'
import { useRef } from "react";
import { useTranslation } from 'react-i18next'


function Contact() {

    const { t } = useTranslation()

    const inputMsg = useRef();

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

    emailjs
      .sendForm(
        emailkey.SERVICE_ID,
        emailkey.TEMPLATE_ID,
        form.current,
        emailkey.PUBLIC_KEY
      )
      .then(
        (result) => {
          var alertSection = document.getElementById("user-message");
          alertSection.classList.remove("hidden");
          if(emailkey.DEBUG_ENABLE){
            console.log(result.text);
          }
        },
        (error) => {
          if(emailkey.DEBUG_ENABLE){
            console.log(error.text);
            alert("FAILED...", error);
          }
        }
      );
  };

  return (
    <div className="Contact">
      <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-gray-900">{t('page.contact.title')}</h1>
          </div>
      </header>

      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="py-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-8 sm:items-start">

            <div className="rounded-lg bg-white shadow col-span-5 space-y-6 mr-10 ml-10 sm:ml-0">
              <h1 className="text-2xl font-bold m-4">{t('contact.reach.me.title')}</h1>
              <p className="m-4">
                {t('contact.reach.me.desc')}
              </p>

              <div className="flex flex-col gap-4 pb-10">
                <ContactLinkBar prefix="@" relatedlabel="email" relatedtitle="email" focusRef={inputMsg}/>
                <ContactLinkBar prefix="in" relatedlink="https://www.linkedin.com/in/nicolas-bazinet-196a8513a/" relatedlabel="LinkedIn" relatedtitle="LinkedIn" addedClasses="" />
                <ContactLinkBar imglink="images/twitter.png" imgalt="Twitter Logo" relatedlink="https://www.twitter.com" relatedlabel="Twitter" relatedtitle="Twitter" addedClasses="" />
              </div>
            </div>

            <div className="rounded-lg bg-white shadow col-span-3 mx-10 sm:mx-0 pb-4 sm:pb-0">

              <div className="m-4 text-center">
                <h1 className="text-xl font-bold uppercase">{t('contact.contact.me.title')}</h1>
              </div>

              <div id="user-message" className="user-message grid justify-items-center flex hidden">
                <div className="flex bg-white flex-row shadow-md border border-gray-100 rounded-lg overflow-hidden md:w-6/12">
                  <div className="flex w-4 bg-gradient-to-t from-green-500 to-green-400"></div>
                  <div className="flex-1 p-3">
                    <h2 className="md:text-l text-gray-600">{t('contact.contact.me.success.title')}</h2>
                    <p className="text-gray-400 text-xs md:text-sm font-light">{t('contact.contact.me.success.desc')}</p>
                  </div>
                </div>
              </div>

              <form ref={form} onSubmit={sendEmail}>
                <div className="p-2 mt-6 mb-6">
                  <label className="block mb-6">
                    <span className="block text-sm font-medium text-slate-700">{t('contact.contact.me.name')}</span>
                    <input type="text" name="name" className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" placeholder={t('contact.contact.me.name')}/>
                  </label>

                  <label className="block">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                      {t('contact.contact.me.email')}
                    </span>
                    <input type="email" name="email" className="peer mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder={t('contact.contact.me.email.txt')} />
                    <p className="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">
                      Please provide a valid email address.
                    </p>
                  </label>

                  <label className="block">
                    <span className="block text-sm font-medium text-slate-700">Message</span>
                    <textarea ref={inputMsg} id="inboxMessage" rows="4" name="message" className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" placeholder={t('contact.contact.me.message.txt')}/>
                  </label>

                </div>

                <div className="grid justify-items-center mb-6">
                  <button type="submit" className="px-8 py-2 font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm">
                    <span>{t('contact.contact.me.send')}</span>
                    <img className="float-right self-center w-6 h-6 rounded-full mx-1" src="images/send-emails.png" alt="Send Emails"></img>
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </main>

      <Footer />

    </div>
  );
}

export default Contact;
