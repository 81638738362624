import React from 'react';


function Project(props) {
    return (
        <div className="sm:mb-0 mb-6 rounded-lg bg-white shadow p-1">
            <div className="">
                <img src={props.imgLink} alt={props.imgAlt} className="rounded-lg"/>
            </div>
            <div className="p-2">
                <h2 className="text-xl font-bold underline pt-4">{props.projectName}</h2>
                <p className="text-md pb-4">{props.description}</p>
            </div>
        </div>
      );
}

export default Project;