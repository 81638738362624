import React from 'react';

function Footer() {
  return (
      <section className="footer w-3/5 mb-10 mx-auto flex items-center justify-center mt-16 mb-12">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-md font-bold text-gray-900">© Nicolas Bazinet * nicolasbazinet.net * 2023</h1>
        </div>
      </section>
  );
}

export default Footer;
