import React from 'react';
import { useTranslation } from 'react-i18next'

function Profile(props) {

    const { t } = useTranslation()

    return (
        <div className="">
            <div className="m-2">
                <h1 className="text-xl font-bold uppercase">Nicolas Bazinet</h1>
                <h2 className="text-lg pb-4">{t('home.profile.main.job.title')}</h2>
            </div>

            <div className="">
                <img src="images/me-profile.jpg" alt="Profile" className=""/>
            </div>

            <div className="pl-2 mt-6 mb-6">
                <ul className="list-disc list-inside space-y-1">
                    <li>Nicolas Bazinet</li>
                    <li>28 October 1996</li>
                    <li>{t('home.profile.job.title')}</li>
                    <li>nicolas.bazinet@hotmail.com</li>
                    <li>{t('home.profile.note1')}</li>
                    <li>{t('home.profile.note2')}</li>
                </ul>
            </div>

            <div className="grid justify-items-center mb-6 pb-4 sm:pb-0">
                <button className="px-8 py-2 font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm">
                    <a href="/files/CV_NicolasBazinet.pdf" target="_blank">
                        <span>{t('home.profile.cv')}</span>
                        <img className="float-right self-center w-5 h-5 rounded-full mx-1" src="images/downloadFileIcon3.png" alt="Download Cv"></img>
                    </a>
                </button>
            </div>
        </div>
      );
}

export default Profile;