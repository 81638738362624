import React from 'react';
import {  Link } from "react-router-dom";

function NavLink(props) {
    return (
        <div>
            <Link className="clickableActive menuNavBar text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to={props.toLink}>{props.linkName}</Link>                  
        </div>
      );
}

export default NavLink;