import React from 'react';

function displayPreSection(props) {
    if(props.prefix) {
        return (
            <span className="w-3">{props.prefix}</span>
        );
    } else if(props.imglink) {
        return (
            <img className="w-3" src={props.imglink} alt={props.imgalt}></img>
        );
    } else {
        return (
            <span className="w-3"></span>
        );
    }
}

function ContactLinkBar(props) {

    const inputElement = props.focusRef;

    const focusInput = () => {
        inputElement.current.focus();
    }

    return (
        <div className="pl-4 flex">
            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-600 bg-gray-600 text-gray-400">
                {displayPreSection(props)}
            </span>
            <a href={props.relatedlink} target="_blank" aria-label={props.relatedlabel} title={props.relatedtitle}
                rel="noopener noreferrer" className="rounded-none rounded-r-lg max-w-xs border border-gray-300 text-gray-900 block flex-1 text-sm border-gray-300 p-2.5 bg-cyan-500 border-gray-600 text-white"
                onClick={focusInput} >
                <p>Nicolas Bazinet</p>
            </a>
        </div>
      );
}

export default ContactLinkBar;