import Home from '../Routes/Home';
import Projects from '../Routes/Projects';
import Experiences from '../Routes/Experiences';
import Contact from '../Routes/Contact';
import NavBar from '../Layouts/NavBar';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App bg-gray-100">
      <Suspense fallback={null}>
          <Router>
            <NavBar />
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/experiences' element={<Experiences />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
          </Router>
      </Suspense>
    </div>

  );
}

export default App;

