import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
    const { i18n } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return (
        <div onChange={changeLanguage} className="ml-3 relative text-gray-300 rounded-md text-sm font-medium">
            <input type="radio" value="en" name="language" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" defaultChecked />
            <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 pr-2">En</label>

            <input type="radio" value="fr" name="language" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-60" />
            <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 pr-2">Fr</label>
        </div>
  )
}

export default LanguageSelector