import '../index.css';
import Footer from '../Layouts/Footer.js';
import React from 'react';
import Profile from '../Components/Home/Profile';
import Service from '../Components/Home/Service';
import { useTranslation } from 'react-i18next'

function Home() {

  const { t } = useTranslation()

  return (
    <div className="Home">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">{t('page.home.title')}</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="py-6 items-start lg:grid lg:grid-flow-row-dense lg:grid-cols-8">

            <div className="rounded-lg bg-white shadow col-span-2 mr-10 ml-5">
              <Profile />
            </div>

            <div className="rounded-lg bg-white shadow col-span-6 space-y-10">
              
              <div className="aboutMe md:shrink-0">
                <div className="m-2">
                  <h1 className="text-2xl font-bold m-4">{t('home.about.me.title')}</h1>
                </div>

                <div className="m-6 space-y-4">
                  <p className="text-justify">
                    {t('home.about.me.1')}
                  </p>
                  <p className="text-justify">
                    {t('home.about.me.2')}
                  </p>
                  <p className="text-justify">
                    {t('home.about.me.3')}
                  </p>
                </div>
              </div>

              <div className="expertize">
                <div className="m-2">
                  <h1 className="text-2xl font-bold m-4">{t('home.expertize.title')}</h1>
                </div>

                <div className="m-6 space-y-4">
                  <p className="text-justify">
                    {t('home.expertize.1')}
                  </p>
                  <p className="text-justify">
                    {t('home.expertize.2')}
                  </p>
                  <p className="text-justify">
                    {t('home.expertize.3')}
                  </p>
                  <p className="text-justify">
                    {t('home.expertize.4')}
                  </p>
                </div>
              </div>              

              <div>
                <div className="myServices">
                  <div className="m-2">
                    <h1 className="text-2xl font-bold m-4">{t('home.services.title')}</h1>
                  </div>
                </div>

                <div className="myServicesBlock sm:grid sm:grid-cols-4 sm:gap-4 p-6">
                  <Service sectionTitle={t('home.services.1.subtitle')} sectionImg="images/staticwebsite_nobg.png" sectionImgAlt="static-website"
                           sectionTxt={t('home.services.1')}/>
                  <Service sectionTitle={t('home.services.2.subtitle')} sectionImg="images/analysis2.png" sectionImgAlt="loop-img"
                           sectionTxt={t('home.services.2')}/>
                  <Service sectionTitle={t('home.services.3.subtitle')} sectionImg="images/techsupporttools.png" sectionImgAlt="tool-creation-img"
                           sectionTxt={t('home.services.3')}/>
                  <Service sectionTitle={t('home.services.4.subtitle')} sectionImg="images/calander2.png" sectionImgAlt="fast-delivery-img"
                           sectionTxt={t('home.services.4')}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </main>

      <Footer />

    </div>
  );
}

export default Home;
