import React from 'react';
import Footer from '../Layouts/Footer.js';
import Experience from '../Components/Experiences/Experience.js';
import { useTranslation } from 'react-i18next'

function Experiences() {

    const { t } = useTranslation()

  return (
    <div className="Experiences">
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">{t('page.exp.title')}</h1>
            </div>
        </header>
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="py-4 grid grid-flow-row-dense grid-cols-1 grid-rows-3 items-start gap-0 sm:gap-10">

                    <Experience imgLink="images/teamlead.png" imgAlt="Profile" sectionTitle={t('experience.tech.lead.title')}
                    sectionTxt={t('experience.tech.lead.desc')}/>

                    <Experience imgLink="images/devops.jpg" imgAlt="Profile" sectionTitle={t('experience.techops.title')}
                    sectionTxt={t('experience.techops.desc')}/>

                    <Experience imgLink="images/Java.jpg" imgAlt="Profile" sectionTitle={t('experience.dev.title')}
                    sectionTxt={t('experience.dev.desc')}/>
                </div>
            </div>
        </main>

        <Footer />

    </div>
  );
}

export default Experiences;
