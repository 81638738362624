import React from 'react';

function NavLogo(props) {
    return (
        <div className="ml-3 relative">
            <div>
            <button type="button" className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span className="sr-only">{props.logoName}</span>
                <a href={props.imgHref} target="_blank" aria-label={props.imgAlt} title={props.imgAlt} rel="noopener noreferrer">
                    <img className="h-8 w-8 rounded-full" src={props.imgLink} alt={props.imgAlt} />
                </a>
            </button>
            </div>
        </div>
      );
}

export default NavLogo;